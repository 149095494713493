.form {
   flex-direction: column;
   gap: 1em;
   padding: 1em;
   background: $c2;
   min-width: 400px;

   @media (max-width:$mobile-size) {
      min-width: 350px;
   }

   h1 {
      color: white;
   }

   .error {
      text-align: center;
      color: $c-danger;
      height: 1.5em;
   }

   .input {
      position: relative;
      justify-content: space-between;
      align-items: baseline;

      >* {
         flex: 1;
      }

      label {
         color: $c1;
      }
   }

   &.donation {
      background: $c3;
      
      h4{
         color: $c4;
      text-align: center;
      }

      input {
         font-weight: 500;
         background: $c4;
         color: white;

         &::placeholder {
            color: #B8B8B8;
            text-align: start;
         }

      }
   }
}