.table-container {
   height: 100%;
   overflow: auto;
   z-index: 0;
   position: relative;
   background: $c2;
   &.loading{
   
      min-height: 250px;
   }
   
   table {
      table-layout: fixed;
      width: 100%;
      height: fit-content;
      
      thead {
         position: sticky;
         top: 0;
         z-index: 1;
         background: $c4;
         color: white;
         font-weight: 700;
         font-size: 1.1em;

         @media (max-width:$mobile-size) {
            font-size: 0.4em;
            font-weight: 500;

         }
      }

      tbody tr {
         background: $tr1;
         position: relative;

         &:nth-child(odd) {
            background: $tr2;
         }


      }

      p {
         font-size: 0.85em;

         @media (max-width:$mobile-size) {
            font-size: 0.45em;

         }
      }

      th {
         padding: 1em 0.5em;
         white-space: nowrap;
         position: relative;
         text-align: center;


         @media (max-width:$mobile-size) {
            // display: none;
         }
      }

      td {
         color: black;
         font-size: 1.1em;
         font-weight: 500;
         padding: 1em 0.5em;
         text-align: center;
         white-space: break-spaces;

         @media (max-width:$mobile-size) {
            padding: 0.15em;
         }

         span.mobile-header {
            display: none;
            flex-basis: 35%;
         }
      }


   }
}