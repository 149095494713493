@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::before,
*::after {
   box-sizing: border-box;
}

* {
   margin: 0;
   padding: 0;
   font: inherit;
}


html,
body {
   height: 100%;
}

html {
   color-scheme: light;
}

html:focus-within {
   scroll-behavior: smooth;
}

body {
   color: $c3;
   background: $c1;
   text-rendering: optimizeSpeed;
   line-height: 1.4;
   font-size: 16px;
   font-family: 'Poppins', sans-serif;

   @media (max-width:$mobile-size) {
      font-size: 14px;
   }
}

div,
section,
header,
article,
footer,
main,
nav,
form,
button {
   display: flex;
   gap: 0.5em;
}

h1 {
   font-size: 3em;
   font-weight: 900;

   @media (max-width:$mobile-size) {
      font-size: 2em;
      font-weight: 800;

   }
}

h2 {
   font-size: 2.5em;
   font-weight: 800;

   @media (max-width:$mobile-size) {
      font-size: 1.5em;
      font-weight: 700;
   }
}

h3 {
   font-size: 2em;
   font-weight: 700;

   @media (max-width:$mobile-size) {
      font-size: 1em;
      font-weight: 600;
   }
}

h4 {
   font-size: 1.5em;
   font-weight: 600;

   @media (max-width:$mobile-size) {
      font-size: 1em;
      font-weight: 500;
   }
}

h5 {
   font-size: 1.25em;
   font-weight: 500;

   @media (max-width:$mobile-size) {
      font-size: 0.9em;
      font-weight: 400;
   }
}

p {
   font-size: 1.15em;

   @media (max-width:$mobile-size) {
      font-size: 1em;
   }
}


button {
   align-items: center;
   justify-content: center;
}

ul,
ol {
   list-style: none;
}

img,
picture,
svg {
   display: block;
   max-width: 100%;
   height: 100%;
   object-fit: contain;
}

button,
a,
label {
   cursor: pointer;
   background-color: transparent;
   color: inherit;
   border: none;
   text-decoration: none;
   white-space: nowrap;
}

label,
li {
   white-space: nowrap;
   line-height: 1.2;
}

input,
select,
textarea {
   outline: none;
   border: none;
   background: none;
   cursor: auto;
   background: white;

}

input {
   padding: 0.25em 0.5em;
   border-radius: 15px;
   font-size: 1.1em;
   font-weight: 400;
   min-height: 25px;
   width: 100%;

   &:-webkit-autofill {
      // color: inherit !important;
      // -webkit-text-fill-color: inherit !important;
      transition: 5000s ease-in-out 0s;
   }

   &[type=checkbox] {
      aspect-ratio: 1;
      width: 25px;
      height: 22px;
      outline: 2px solid #F0AD4E;
      outline-offset: -3px;
   }
}

select {
   cursor: pointer;
   padding: 0.25em 0.25em 0.25em 0.5em;
   border-radius: 15px;
   font-size: 1.1em;
   font-weight: 400;
   height: 2em;
}

textarea {
   background: white;
   padding: 0.25em 0.5em;
   border-radius: 15px;
   font-size: 1.1em;
   font-weight: 400;
   min-height: 25px;
}

dialog {
   margin: auto;
   border: none;
   user-select: none;
   visibility: unset;

   &:focus-visible {
      outline: none;

   }

   button:focus-visible {
      border-radius: none;
      border: none;
      outline: none;
   }
}

dialog::backdrop {
   background: rgb(0 0 0 / 0.4);
}