.App {
   flex-direction: column;
   height: 100vh;
   width: 100vw;
   gap: 0;
   overflow: auto;
   position: relative;
}

.column {
   flex-direction: column;
}

.loader {
   position: absolute;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 999;
}

#sandwatchloader {
   @keyframes lqt {

      0%,
      100% {
         background-image: linear-gradient($c1 , 40px, transparent 0);
         background-position: 0% 0px;
      }
   
      50% {
         background-image: linear-gradient($c1 , 40px, transparent 0);
         background-position: 0% 40px;
      }
   
      51% {
         background-image: linear-gradient($c1 , 40px, transparent 0);
         background-position: 0% -40px;
      }
   }
   @keyframes lqb {
      0% {
         background-image: linear-gradient($c1 , 40px, transparent 0);
         background-position: 0 40px;
      }
   
      100% {
         background-image: linear-gradient($c1 , 40px, transparent 0);
         background-position: 0 -40px;
      }
   }
   @keyframes spinx {

      0%,
      49% {
         transform: rotate(0deg);
         background-position: 50% 36px;
      }
   
      51%,
      98% {
         transform: rotate(180deg);
         background-position: 50% 4px;
      }
   
      100% {
         transform: rotate(360deg);
         background-position: 50% 36px;
      }
   }
   box-sizing: border-box;
   display: inline-block;
   width: 50px;
   height: 80px;
   border-top: 5px solid #fff;
   border-bottom: 5px solid #fff;
   background: linear-gradient($c1, 30px, transparent 0) no-repeat;
   background-size: 2px 40px;
   background-position: 50% 0px;
   animation: spinx 5s linear infinite;
   left: calc(50% - 25px);

   &:before,
   &:after {
      content: "";
      width: 40px;
      left: 50%;
      height: 35px;
      position: absolute;
      top: 0;
      transform: translatex(-50%);
      background: rgba(255, 255, 255, 0.4);
      border-radius: 0 0 20px 20px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: 0 0px;
      animation: lqt 5s linear infinite;
   }

   &:after {
      top: auto;
      bottom: 0;
      border-radius: 20px 20px 0 0;
      animation: lqb 5s linear infinite;
   }
}

::-webkit-scrollbar {
   width: 0.75em;
   height: 0.75em;
}

::-webkit-scrollbar-track {
   background: $c2;
}

::-webkit-scrollbar-thumb {
   background: $c3;
   border-radius: 5px;
}

::-webkit-calender-picker-indicator {
   background: white;
   padding: 0.5em;
   cursor: pointer;
   border-radius: 5px;
}


// :-webkit-autofill:hover,
// :-webkit-autofill:focus,
// :-webkit-autofill:active {
//    background-color: inherit !important;
//    -webkit-text-fill-color: #ffffff !important;
// }
