.dialog {
   width: 100vw;
   z-index: 999;
   height: 100vh;
   background: rgb(0 0 0 / 0.3);
   filter: drop-shadow(1px 1px 1px $c3);
   display: grid;
   place-items: center;
   cursor: no-drop;

   .dialog-content {
      min-height: 300px;
      min-width: 250px;
      cursor: default;
      background: $c2;
      flex-direction: column;
      max-width: 95%;
      max-height: 90%;
      z-index: 999;
      border: 4px solid black;
      overflow-y: hidden;
      gap: 0;

      header {
         gap: 1em;
         position: sticky;
         top: 0;
         right: 0;
         justify-content: space-between;
         align-items: center;
         padding: 1em;
         border-bottom: 4px solid black;
         outline-offset: 0px;
         background: $c1;
         z-index: 11;

         h1 {
            flex: 1;
            color: white;
         }
      }

      main {
         padding: 1em;
         flex-direction: column;
         gap: 1em;
      }
   }
}